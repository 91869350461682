import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface Props extends NumberFormatProps {
  value: number;
}

const FormatMillionNumber = ({ value, ...rest }: Props) => (
  <NumberFormat
    value={
      value.toString().length > 6
        ? (value / 1000000).toFixed(1)
        : (value / 1000).toFixed(2)
    }
    displayType="text"
    thousandSeparator
    suffix={value.toString().length > 6 ? ' M' : ' k'}
    {...rest}
  />
);

export default FormatMillionNumber;
