import {
  CampaignData,
  CampaignListRes,
  ReportListRes,
  CampaignReportListItem,
  ConfigDonation,
  EinListRes,
  PartnerByCampaign,
} from 'interfaces/campaign';
import * as types from './types';

export const setCampaignList = (campaignList: CampaignListRes) =>
  ({
    type: types.SET_Campaign_LIST,
    payload: campaignList,
  } as const);

export const setReportList = (reportList: ReportListRes) =>
  ({
    type: types.SET_Report_LIST,
    payload: reportList,
  } as const);

export const createCampaign = (campaign: CampaignData) =>
  ({
    type: types.CREATE_Campaign,
    payload: campaign,
  } as const);

export const deleteCampaign = (ids: string[]) =>
  ({ type: types.DELETE_Campaign, payload: ids } as const);

export const setCampaign = (campaign: CampaignData) =>
  ({
    type: types.SET_Campaign,
    payload: campaign,
  } as const);

export const saveConfigDonat = (donation: ConfigDonation) =>
  ({
    type: types.SAVE_CONFIG_DONATION,
    payload: donation,
  } as const);

export const getDonationDetails = (donationDetails: any) =>
  ({
    type: types.GET_DONATION_DETAILS,
    payload: donationDetails,
  } as const);

export const setEinSuggest = (list: EinListRes) =>
  ({
    type: types.SET_EIN_SUGGEST,
    payload: list,
  } as const);

export const setAtworkPartnerPreviewUrl = (url: string) =>
  ({
    type: types.SET_ATWORK_PARTNER_PREVIEW_URL,
    payload: url,
  } as const);

export const setCurrentAllCampaigns = (campaigns: CampaignData[]) =>
  ({
    type: types.SET_CURRENT_ALL_CAMPAIGNS,
    payload: campaigns,
  } as const);

export const setPartnersByCampaign = (parnters: PartnerByCampaign[]) =>
  ({
    type: types.SET_PARTNERS_BY_CAMPAIGN,
    payload: parnters,
  } as const);

export const setCampaignReports = (reports: CampaignReportListItem[]) =>
  ({
    type: types.SET_CAMPAIGN_REPORTS,
    payload: reports,
  } as const);

export const setCampaignQRCode = (base64Str: string) =>
  ({
    type: types.SET_CAMPAIGN_QRCODE,
    payload: base64Str,
  } as const);

export const clearCampaignQRCode = () =>
  ({
    type: types.CLEAR_CAMPAIGN_QRCODE,
    payload: '',
  } as const);

export const clearFundraisingReport = () =>
  ({
    type: types.CLEAR_REPORT_PAGE,
    payload: undefined,
  } as const);
