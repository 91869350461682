import React from 'react';
import { IProps as InjectedProps } from 'react-range/lib/types';
import { RangeSliderProps } from '../interfaces';
import UIRangeSlider from './UIRangeSlider';
import { FormatMillionNumber } from '../format';

const STEP = 0.1;
const MIN = 1;
const MAX = 100;

type Props = RangeSliderProps & Partial<InjectedProps>;

const MoneyRangeSlider = ({
  values = [],
  step = STEP,
  min = MIN,
  max = MAX,
  onChange,
  ...rest
}: Props) => {
  const handleChange = (vals: number[]) => {
    onChange?.(vals);
  };

  return (
    <UIRangeSlider
      values={values?.length ? values : []}
      step={step}
      min={min}
      max={max}
      onChange={handleChange}
      modifyValueFunc={value => <FormatMillionNumber value={value} prefix="$ " />}
      {...rest}
    />
  );
};

export default MoneyRangeSlider;
