import * as api from './api';
import * as actions from './actions';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import {
  ContactCombineCompany,
  CustomizeTemplateGetReq,
  CustomizeTemplatePreviewReq,
  CustomizeTemplateSaveReq,
  Gameplan,
  GameplansByTeamReq,
  NewGameplan,
  ReqChangeGameplanNameAndClose,
  ReqNewGameplan,
} from 'interfaces/gameplan';
import { GameplanFormData } from 'pages/organizationUser/gameplan/components/GamePlanTemplate';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { RequestPayloadTooLargeError } from 'services/ApiService';

export const fetchSaveListContacts = (savelistId: string) => async (
  dispatch: Dispatch,
): Promise<ContactCombineCompany[] | undefined> => {
  return api
    .fetchSaveListContacts(savelistId)
    .then(savelistContacts => {
      dispatch(actions.setSaveListContacts(savelistContacts));
      return savelistContacts;
    })
    .catch(err => {
      fetchErrorHandler(err);
      return undefined;
    });
};

export const fetchNewGameplan = (param: ReqNewGameplan) => async (dispatch: Dispatch) => {
  return api
    .fetchNewGameplan(param)
    .then(newGameplan => {
      dispatch(actions.setNewGameplan(newGameplan));
    })
    .catch(() => Toast.error('Error get new gameplan'));
};

export const fetchGetNewGameplan = (param: ReqNewGameplan) => async (): Promise<
  NewGameplan | undefined
> => {
  return api
    .fetchNewGameplan(param)
    .then(newGameplan => {
      return newGameplan;
    })
    .catch(() => {
      Toast.error('Error get new gameplan');
      return undefined;
    });
};

export const fetchCreateGameplan = (gameplan: NewGameplan) => async () => {
  return api.fetchCreateGameplan(gameplan).catch(err => {
    if (err.response.data.message) {
      Toast.error(err.response.data.message);
      throw err;
    }

    if (err === RequestPayloadTooLargeError) {
      Toast.error('The maximum size of this gameplan is 10M.');
      Toast.error('Error create gameplan');
      throw err;
    }

    Toast.error('Error create gameplan');
    throw err;
  });
};

export const fetchGameplan = (gameplanId: string) => async (
  dispatch: Dispatch,
): Promise<Gameplan | undefined> => {
  return api
    .fetchGameplan(gameplanId)
    .then(value => {
      dispatch(actions.setGameplan(value));
      return value;
    })
    .catch(() => {
      Toast.error('Error get gameplan');
      return undefined;
    });
};

export const fetchGameplansBySavelist = (savelistId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchGameplansBySavelist(savelistId)
    .then(gameplans => {
      const gameplanSortByDate = gameplans[0].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
      dispatch(actions.setGameplans(gameplanSortByDate));
    })
    .catch(() => Toast.error('Error get gameplans by savelist'));
};

export const fetchChangeGameplanNameAndClose = (
  param: ReqChangeGameplanNameAndClose,
) => async (dispatch: Dispatch) => {
  return api
    .fetchChangeGameplanNameAndClose(param)
    .then(gameplan => {
      dispatch(
        actions.setGameplanNameAndClose({
          id: gameplan.id,
          name: gameplan.name,
          isClose: gameplan.isClose,
        }),
      );
    })
    .catch(() => Toast.error('Error set gameplan close state'));
};

export const fetchChangeSingleGameplanNameAndClose = (
  param: ReqChangeGameplanNameAndClose,
) => async (dispatch: Dispatch) => {
  return api
    .fetchChangeGameplanNameAndClose(param)
    .then(gameplan => {
      dispatch(
        actions.setGameplanNameAndCloseSingle({
          id: gameplan.id,
          name: gameplan.name,
          isClose: gameplan.isClose,
        }),
      );
    })
    .catch(() => Toast.error('Error set gameplan close state'));
};

export const fetchChangeGameplanNameAndCloseByTeam = (
  param: ReqChangeGameplanNameAndClose,
) => async (dispatch: Dispatch) => {
  return api
    .fetchChangeGameplanNameAndClose(param)
    .then(gameplan => {
      dispatch(
        actions.setGameplanNameAndCloseByTeam({
          id: gameplan.id,
          name: gameplan.name,
          isClose: gameplan.isClose,
        }),
      );
    })
    .catch(() => Toast.error('Error set gameplan close state'));
};

export const fetchDeleteGameplan = (gameplanId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchDeleteGameplan(gameplanId)
    .then(({ status }) => {
      if (status !== 'success') {
        Toast.error('Fail delete gameplan');
        return;
      }
      dispatch(actions.deleteGameplan(gameplanId));
    })
    .catch(() => Toast.error('Error delete gameplan'));
};

export const fetchDeleteGameplanContact = (gameplanContactId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchDeleteGameplanContact(gameplanContactId)
    .then(({ status }) => {
      if (status !== 'success') {
        Toast.error('Fail delete gameplan contact');
        return;
      }
      dispatch(actions.deleteGameplanContact(gameplanContactId));
      Toast.success('Gameplan contact successfully deleted ');
    })
    .catch(() => Toast.error('Error delete gameplan contact'));
};

export const fetchGameplanStatus = (gameplanId: string) => async (dispatch: Dispatch) => {
  return api
    .fetchGameplanStatus(gameplanId)
    .then(gameplanstatus => {
      dispatch(actions.setGameplanStatus(gameplanstatus));
    })
    .catch(() => Toast.error('Error get gameplan status'));
};

export const fetchGameplansByTeam = (param: GameplansByTeamReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchGameplansByTeam(param)
    .then(gameplans => {
      dispatch(actions.setGameplansByTeam(gameplans));
    })
    .catch(() => Toast.error('Error get gameplan by team'));
};

export const clearGameplan = () => async (dispatch: Dispatch) => {
  dispatch(actions.setGameplan(undefined));
  dispatch(actions.setGameplans([]));
  dispatch(actions.setGameplanStatus(undefined));
};

export const fetchSuggestGameplans = (name: string) => async (dispatch: Dispatch) => {
  return api
    .fetchSuggestGameplans(name)
    .then(suggestGameplans => {
      dispatch(actions.setSuggestGameplans(suggestGameplans));
    })
    .catch(() => Toast.error('Error get suggest gameplans'));
};

export const fetchGameplanCustomizeTemplateSave = (
  param: CustomizeTemplateSaveReq,
) => async (dispatch: Dispatch) => {
  return api
    .fetchGameplanCustomizeTemplateSave(param)
    .then(template => {
      dispatch(actions.setGameplanCustomizeTemplate(template));
      Toast.success('Customize template save successfully');
      api
        .fetchGameplanCustomizeTemplatePreview({
          body: template.body,
          templateType: template.templateType,
        })
        .then(templatePreview =>
          dispatch(
            actions.setNewGameplanTemplates(templatePreview, template.templateType),
          ),
        );
    })
    .catch(err => {
      if (err === RequestPayloadTooLargeError) {
        Toast.error('The maximum size of this gameplan is 10M.');
        Toast.error('Error save Customize template');
        throw err;
      }

      if (err.response.data.message) {
        return Toast.error(err.response.data.message);
      }

      Toast.error('Error save Customize template');
    });
};

export const fetchGameplanCustomizeTemplate = (param: CustomizeTemplateGetReq) => async (
  dispatch: Dispatch,
) => {
  return api.fetchGameplanCustomizeTemplate(param).then(template => {
    dispatch(actions.setGameplanCustomizeTemplate(template));
  });
};
export const fetchGameplanCustomizeTemplateReset = (
  param: CustomizeTemplateGetReq,
) => async (dispatch: Dispatch) => {
  return api.fetchGameplanCustomizeTemplateReset(param).then(template => {
    dispatch(actions.setGameplanCustomizeTemplate(template));
    Toast.success('Customize template reset successfully');
    api
      .fetchGameplanCustomizeTemplatePreview({
        body: template.body,
        templateType: template.templateType,
      })
      .then(templatePreview =>
        dispatch(actions.setNewGameplanTemplates(templatePreview, template.templateType)),
      );
  });
};
export const fetchGameplanCustomizeTemplatePreview = (
  param: CustomizeTemplatePreviewReq,
) => async (dispatch: Dispatch) => {
  return api.fetchGameplanCustomizeTemplatePreview(param).then(template => {
    dispatch(actions.setGameplanCustomizeTemplatePreview(template));
  });
};

export const fetchPitchEmailContent = (contactId: string) => async () => {
  return api.fetchPitchEmailContent(contactId);
};

export const updateNewGameplanTemplateByForm = (formValue: GameplanFormData) => async (
  dispatch: Dispatch,
) => {
  dispatch(actions.setNewGameplanTemplateByForm(formValue));
};

export const fetchUpdateGameplan = (gameplan: NewGameplan) => async () => {
  return api.fetchUpdateGameplan(gameplan).catch(err => {
    if (err?.response?.data.message) {
      Toast.error(err.response.data.message);
      throw err;
    }

    if (err === RequestPayloadTooLargeError) {
      Toast.error('The maximum size of this gameplan is 10M.');
      Toast.error('Error create gameplan');
      throw err;
    }

    Toast.error('Error create gameplan');
    throw err;
  });
};

export const fetchGameplanAnalytics = (savedListId: string) => async () => {
  return api.fetchGameplan(savedListId, 'true');
};

export const fetchGameplanEmailAnalytics = (gameplanId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchGameplanEmailAnalytics(gameplanId)
    .then(gameplan => {
      dispatch(actions.setGameplanEmailAnalyticsContacts(gameplan.gamePlanContacts));
    })
    .catch(() => Toast.error('Error get Recipients'));
};

export const clearEditGameplan = () => async (dispatch: Dispatch) => {
  dispatch(actions.setGameplan(undefined));
  dispatch(actions.setNewGameplan(undefined));
  dispatch(actions.setSaveListContacts([]));
};
