import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { UIModalProps } from 'components/UI/interfaces';
import { UIButton, UIModal } from 'components/UI';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { PitchProps, PitchValues } from 'interfaces/common';
import { cropText } from 'utils/formatters';
import PitchForm from 'components/Forms/PitchForm';
import { useFetchData, useModal } from 'hooks/common';
import { Form } from 'react-final-form';
import { Field } from 'components/FinalForm';
import { FieldTextInput } from 'components/FormFields';
import JumpAlertLink from 'components/JumpAlertLink';
import { fetchEmailPreview } from 'store/ducks/utility/api';
import HTMLPreviewModal from '../HTMLPreviewModal';

interface BaseProps {
  onSubmit: (values: PitchValues) => OnSubmitReturnType;
  onSendTestEmail: (values: PitchValues, testEmail: string) => void;
}

type Props = BaseProps & UIModalProps & PitchProps;

const nameLength = 15;
const emailLength = 18;

function PitchModal({
  onClose,
  pitch,
  onSubmit = () => {},
  onSendTestEmail,
  ...modalProps
}: Props) {
  const [onFetchEmailPreview, emailHtml, isEmailPreviewLoading] = useFetchData(
    fetchEmailPreview,
  );

  const [pitchFormValues, setPitchFormValues] = useState<PitchValues | undefined>(
    undefined,
  );
  const { recipient, sender } = pitch;

  const senderName = cropText(sender.name, nameLength);
  const senderEmail = cropText(sender.email, emailLength);

  const [showTestEmailModal, hideTestEmailModal] = useModal(
    () => (
      <TestEmailModal onClose={hideTestEmailModal} hideCloseIcon>
        <Form
          onSubmit={values => {
            pitchFormValues && onSendTestEmail(pitchFormValues, values.testEmail);
            hideTestEmailModal();
          }}
          render={({ handleSubmit }) => (
            <div>
              <TestEmailInputTitle>Send Test Email</TestEmailInputTitle>
              <Field
                name="testEmail"
                label="Test Email Recipient"
                component={FieldTextInput}
              />
              <ButtonWrapper>
                <UIButton
                  style={{ width: '100px' }}
                  title="Close"
                  modifiers="secondary"
                  handleClick={hideTestEmailModal}
                />
                <UIButton
                  style={{ width: '100px' }}
                  title="Send"
                  modifiers="primary"
                  handleClick={handleSubmit}
                />
              </ButtonWrapper>
            </div>
          )}
        />
      </TestEmailModal>
    ),
    [pitchFormValues],
  );

  const [showPreviewModal, hidePreviewModal] = useModal(() => {
    return (
      <HTMLPreviewModal
        html={emailHtml}
        isloading={isEmailPreviewLoading}
        onClose={hidePreviewModal}
      />
    );
  }, [emailHtml, isEmailPreviewLoading]);

  return (
    <UIModal
      onClose={onClose}
      {...modalProps}
      propsBodyCss={{ padding: '28px 40px 40px' }}
      style={{
        width: '100%',
        maxWidth: '844px',
        top: '100px',
      }}
      overlayStyle={{
        overflow: 'auto',
      }}
      header={
        <ModalTitle>
          Pitch
          <AlertLink path="/email-design">Email Design</AlertLink>
        </ModalTitle>
      }
    >
      <HeaderPitch>
        <HeaderPitchInner>
          <HeaderText>To</HeaderText>
          <UserInfoList>
            {recipient.map(item => (
              <UserInfo key={item.email}>
                <span data-tip={item.name}>{cropText(item.name, nameLength)}</span>

                <span data-tip={item.email}>{cropText(item.email, emailLength)}</span>
              </UserInfo>
            ))}
          </UserInfoList>
        </HeaderPitchInner>
        <HeaderPitchInner>
          <HeaderText>From</HeaderText>
          <UserInfo>
            <span data-tip={sender.name}>{senderName}</span>

            <span data-tip={sender.email}>{senderEmail}</span>
          </UserInfo>
        </HeaderPitchInner>
        <ReactTooltip className="tooltip-info" place="top" type="info" effect="solid" />
      </HeaderPitch>
      <PitchForm
        onSubmit={onSubmit}
        onSendTestEmail={values => {
          setPitchFormValues(values);
          showTestEmailModal();
        }}
        closeHandler={onClose}
        initialValues={{
          attachments: pitch.attachments,
          text: pitch.text,
          bcc: '',
          subject: 'Social Impact Partnership – Accelerist VIP',
        }}
        onClickPreviewEmail={(body, subject) => {
          onFetchEmailPreview({
            body,
            subject,
            attachments: [],
            emailTo: recipient[0]?.email || '',
          });
          showPreviewModal();
        }}
      />
    </UIModal>
  );
}

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  padding: 40px 0 16px;
  display: flex;
`;

const HeaderPitch = styled.div`
  margin-bottom: 12px;
`;

const HeaderPitchInner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const HeaderText = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--darkGray);
  margin-right: 50px;
  width: 29px;
`;

const UserInfo = styled.div`
  display: flex;
  width: 100%;
  max-width: 271px;
  justify-content: space-between;
  background: #e8e8e8;
  border-radius: 13px;
  font-size: 12px;
  line-height: 150%;
  color: var(--black);
  padding: 4px 19px;
  cursor: default;
  margin-right: 6px;
  white-space: nowrap;

  & > span:first-child {
    margin-right: 8px;
  }
`;

const UserInfoList = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
`;

const TestEmailModal = styled(UIModal)`
  width: 576px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TestEmailInputTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  color: var(--black);
  margin: 0px auto 46px;
  text-align: center;
`;

const AlertLink = styled(JumpAlertLink)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export default PitchModal;
