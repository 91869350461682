import * as api from './api';
import StorageService from 'services/Storage';
import Toast from 'services/Toast';
import { AuthData } from 'interfaces/auth';
import * as actions from './actions';
import { Dispatch } from 'redux';
import { UserProfile } from 'components/Forms/interfaces';
import { fetchErrorHandler } from 'utils/errorHandlers';
import { GetParentsRegionRes } from 'interfaces/companies';

export const fetchSignUp = (payload: AuthData) => async (dispatch: Dispatch) => {
  return api
    .fetchSignUp(payload)
    .then(response => {
      const { accessToken, user } = response;
      StorageService.setItem('accessToken', accessToken);
      dispatch(actions.setUser(user));
    })
    .catch(error => {
      Toast.error(error.response.data.message || 'Incorrect password or email');
    });
};

export const fetchSignIn = (payload: AuthData) => async (dispatch: Dispatch) => {
  return api
    .fetchSignIn(payload)
    .then(response => {
      const { accessToken, user } = response;
      StorageService.setItem('accessToken', accessToken);
      dispatch(actions.setUser(user));
    })
    .catch(err => {
      fetchErrorHandler(err);
    });
};

export const fetchChangePasswordEmail = (payload: AuthData) => async () => {
  return api
    .fetchChangePasswordEmail(payload)
    .then(() => {})
    .catch(() => {
      Toast.error('Incorrect email');
    });
};

export const fetchChangePassword = (payload: AuthData) => async () => {
  const KEY = 'token';
  const { [KEY]: removed, ...withoutKey } = payload;

  return api
    .fetchChangePassword(withoutKey, removed)
    .then(() => {})
    .catch(() => {
      Toast.error('Incorrect password');
    });
};

export const fetchUpdatePassword = (payload: AuthData) => async () => {
  try {
    await api.fetchUpdatePassword(payload);

    Toast.success('Password created successfully');
  } catch (e) {
    Toast.error('Incorrect password');
  }
};

export const currentUserGet = () => async (dispatch: Dispatch) => {
  return api
    .getCurrentUser()
    .then(response => {
      dispatch(actions.setUser(response));
    })
    .catch(() => {});
};

export const additionalInfoGet = () => async (dispatch: Dispatch) => {
  return api
    .getAdditionalInfo()
    .then(response => {
      dispatch(actions.setAdditionalInfo(response));
    })
    .catch(() => {});
};

export const handleLogout = () => async (dispatch: Dispatch) => {
  dispatch(actions.logout());
  return StorageService.removeItem('accessToken');
};

export const fetchUploadUserAvatar = (file: File) => async () => {
  return api
    .fetchUploadUserAvatar(file)
    .then(() => {
      return Toast.success('Avatar successfully uploaded');
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchSignedUrl = (key: string) => async () => {
  return api
    .fetchSignedUrl(key)
    .then(logo => logo)
    .catch(error => {
      throw error;
    });
};

export const fetchUpdatePersonalInfo = (payload: UserProfile) => async (
  dispatch: Dispatch,
) => {
  try {
    const data = await api.fetchUpdatePersonalInfo(payload);
    Toast.success('User info successfully updated');
    dispatch(actions.setUser(data));
  } catch (e) {
    Toast.error('Incorrect data');
  }
};

export const fetchVerifyEmailStatus = () => async (dispatch: Dispatch) => {
  return api
    .fetchVerifyEmailStatus()
    .then(status => dispatch(actions.setVerifyEmailStatus(status)));
};

export const fetchVerifyEmail = () => async () => {
  return api
    .fetchVerifyEmail()
    .then(() => Toast.success('verify email sent successfully'))
    .catch(err => {
      fetchErrorHandler(err);
    });
};

export const fetchGetStateByCountry = (countries: string[]) => async (
  dispatch: Dispatch,
) => {
  if (!countries.length) return dispatch(actions.setStateByCountry([]));
  return api
    .fetchGetStateByCountry({ countries })
    .then(states => {
      dispatch(actions.setStateByCountry(states));
    })
    .catch(() => {
      Toast.error('Error get states');
    });
};

export const fetchGetCitesByStates = (states: string[]) => async (dispatch: Dispatch) => {
  if (!states.length) return dispatch(actions.setGetCitesByStates([]));
  return api
    .fetchGetCitesByStates({ states })
    .then(cites => {
      dispatch(actions.setGetCitesByStates(cites));
    })
    .catch(() => {
      Toast.error('Error get cites');
    });
};

export const fetchGetParentsRegion = (params: {
  region: string;
  type: 'state' | 'city';
}) => async (): Promise<GetParentsRegionRes | undefined> => {
  return api.fetchGetParentsRegion(params).catch(() => {
    Toast.error('Error get region');
    return undefined;
  });
};
