import { StateProps } from 'store/interfaces';

export const getSavelistContacts = state => state.gameplan.savelistContacts;
export const getNewGameplan = state => state.gameplan.newGameplan;
export const getGameplans = state => state.gameplan.gameplans;
export const getGameplan = (state: StateProps) => state.gameplan.gameplan;
export const getGameplanStatus = (state: StateProps) => state.gameplan.gameplanStatus;
export const getGameplansByTeamItems = (state: StateProps) =>
  state.gameplan.gameplansByTeam?.items;
export const getGameplansByTeamMeta = (state: StateProps) =>
  state.gameplan.gameplansByTeam?.meta;
export const getSuggestGameplans = (state: StateProps) =>
  state.gameplan.suggestGameplans.items.map(item => ({ id: item.id, name: item.name }));
export const getGameplanCustomizeTemplate = (state: StateProps) =>
  state.gameplan.customizeTemplate;
export const getGameplanCustomizeTemplatePreview = (state: StateProps) =>
  state.gameplan.customizeTemplatePreview;
export const getGameplanEmailAnalyticsContacts = (stage: StateProps) =>
  stage.gameplan.emailAnalyticsContacts;
