import {
  InputHTMLAttributes,
  ChangeEvent,
  ButtonHTMLAttributes,
  MouseEvent,
  KeyboardEvent,
  ReactNode,
  FocusEvent,
} from 'react';
import { Option as MultiSelectOption } from 'react-multi-select-component/dist/lib/interfaces';
import { CSSObject } from 'styled-components';

export type ModifierBtn =
  | 'primary'
  | 'secondary'
  | 'blue'
  | 'third'
  | 'danger'
  | 'dangerBg'
  | 'normal'
  | 'lightBlue';

export interface UITextFieldProps<InputElementType extends HTMLElement = HTMLElement> {
  onChange?: (e: ChangeEvent<InputElementType>) => void;
  onFocus?: () => void;
  onBlur?: (e: FocusEvent<InputElementType>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: () => void;
  placeholder?: string;
  placeholderSize?: number;
  value?: string | number | string[];
  required?: boolean;
  type?: InputHTMLAttributes<''>['type'];
  error?: boolean;
  min?: number;
  autofocus?: boolean;
  disabled?: boolean;
  inputCss?: CSSObject;
  className?: string;
  readOnly?: boolean;
}

export interface UICheckboxProps {
  handleClick?: (label?: string) => void;
  label?: string;
  check?: boolean;
  disabled?: boolean;
  className?: string;
  labelCss?: CSSObject;
  value?: string;
}

export interface SelectOption<T extends unknown = string> {
  value: T;
  label: string;
}

export interface DropDownOption {
  value: string;
  label: string;
}

export interface SingleSelectProps<T extends unknown = string> {
  handleSelect: (value: SelectOption<T> | null) => void;
  options: readonly SelectOption<T>[];
  children?: Node;
  value?: string | null | number | boolean | object;
  placeholder: string;
  disabled?: boolean;
  isClearable?: boolean;
  error?: boolean;
  onBlur?: () => void;
  className?: string;
}

export interface UIRadioBtnProps {
  handleCheck: () => void;
  label?: string | number;
  isChecked: boolean;
  disabled?: boolean;
  className?: string;
}

export interface CheckBoxItem {
  id: string;
  title: string;
  value?: string;
}

export interface UISuggestProps {
  handleGetSuggestions: (value: string) => void;
  handleSetValue: (suggestion?: UISuggestOption) => void;
  placeholder: string;
  list: UISuggestOption[];
  error: boolean;
}

export interface UIButtonProps {
  modifiers?: ModifierBtn | Array<ModifierBtn>;
  disabled?: boolean;
  handleClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  title?: ReactNode;
  type?: ButtonHTMLAttributes<''>['type'];
  isLoading?: boolean;
  propsCss?: CSSObject;
  className?: string;
  asBtn?: React.ElementType | keyof JSX.IntrinsicElements;
}

export interface UISpinnerProps {
  width?: number;
  height?: number;
  className?: string;
  color?: string;
  secondaryColor?: string;
  propsCss?: CSSObject;
}

export interface UIModalProps {
  onClose: () => void;
  children?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  hideCloseIcon?: boolean;
  style?: CSSObject;
  overlayStyle?: CSSObject;
  propsBodyCss?: CSSObject;
  isloading?: boolean;
  headerStyle?: CSSObject;
  isOpen?: boolean;
}

export interface UIOpenModalProps extends UIModalProps {
  isOpen: boolean;
}

export interface DefaultItemRendererProps {
  checked: boolean;
  option: MultiSelectOption;
  disabled?: boolean;
  onClick;
}

export enum formatValueEnum {
  OPTION_ARRAY,
  BASE_ARRAY,
}
export interface DropDownOption {
  value: string;
  label: string;
}
export interface BaseMultiSelectProps {
  values?: MultiSelectOption[];
  changeHandler?: (values: any) => void;
  visibleNumberToTooltip?: number;
  formatValue?: formatValueEnum;
  limit?: number;
  disabled?: boolean;
  error?: boolean;
}

export interface SelectPanelProps {
  ItemRenderer?: Function;
  options: MultiSelectOption[];
  value: MultiSelectOption[];
  focusSearchOnOpen?: boolean;
  selectAllLabel?: string;
  onChange: (selected: MultiSelectOption[]) => void;
  disabled?: boolean;
  disableSearch?: boolean;
  hasSelectAll?: boolean;
  filterOptions?: (options: MultiSelectOption[], filter: string) => MultiSelectOption[];
  overrideStrings?: {
    [key: string]: string;
  };
  ClearIcon?: any;
}

export interface RangeSliderProps {
  defaultValues?: number[];
  modifyValueFunc?: (value: number) => ReactNode;
}

export interface RadioButtonsLineProps {
  options: SelectOption[];
  value?: SelectOption['value'];
  onChange?: (value: SelectOption['value'] | undefined) => void;
}

export interface OnCheckParams {
  check: boolean;
  id: string;
}

export interface UISuggestOption {
  name: string;
  id: string | number | true;
  [key: string]: any;
}
