import * as api from './api';
import * as actions from './actions';
import { Dispatch } from 'redux';
import Toast from 'services/Toast';
import {
  CustomizeFilterRequestProps,
  PaginationReq,
  SearCompaniesRequestProps,
} from 'interfaces/common';
import { StateProps } from 'store/interfaces';
import {
  AcceptInviteData,
  Companies,
  CompaniesContacts,
  CompanyContact,
  ReqSupportEmail,
  SearchCompany,
} from 'interfaces/companies';
import axios from 'axios';
import { fetchErrorHandler } from 'utils/errorHandlers';

export const fetchCompaniesSearch = (query: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompaniesSearch(query)
    .then(companies => {
      dispatch(actions.setSearchCompanies(companies));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchContactPitch = (
  params: {
    contactId: string;
    companyId: string;
  }[],
) => async (dispatch: Dispatch) => {
  const contactIds = params.map(item => item.contactId);
  const companyIds = params.map(item => item.companyId);
  return api
    .fetchContactPitch(contactIds)
    .then(() => {
      dispatch(actions.setContactsUsedIn60Days(companyIds, contactIds, '1'));
    })
    .catch(console.error);
};

export const fetchCompanies = (params: SearCompaniesRequestProps) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanies(params)
    .then(companies => {
      if (params.dataType === 'contact')
        return dispatch(actions.setCompaniesContacts(companies as CompaniesContacts));

      return dispatch(actions.setCompanies(companies as Companies));
    })
    .catch(error => {
      console.error(error);
      return error;
    });
};

export const fetchCompanyContacts = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyContacts(id)
    .then(contacts => {
      dispatch(actions.setContacts(contacts));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchCompany = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompany(id)
    .then(company => {
      dispatch(actions.setCompany(company));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchCompanyScoops = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyScoops(id)
    .then(scoops => {
      dispatch(actions.setCompanyScoops(scoops));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchCompanyNews = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyNews(id)
    .then(news => {
      dispatch(actions.setCompanyNews(news));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchCompanyLike = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyLike(id)
    .then(() => {
      dispatch(actions.setCompanyFavorite(id));
      dispatch(actions.setLastMonthCompanyLike(id));
      return Toast.success('Company successfully liked');
    })
    .catch(() => {
      return Toast.error('Error company liked');
    });
};

export const fetchCompanyDisLike = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyDisLike(id)
    .then(() => {
      dispatch(actions.setCompanyFavorite(id));
      dispatch(actions.setLastMonthCompanyLike(id));
      return Toast.success('Company successfully disliked');
    })
    .catch(() => {
      return Toast.error('Error company disliked');
    });
};

export const fetchCompaniesFavorites = (page: number, limit?: number) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompaniesFavorites(page, limit)
    .then(companies => {
      dispatch(actions.setCompaniesFavorites(companies));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchCompanyFavoritesDisLike = (id: string) => async () => {
  return api
    .fetchCompanyDisLike(id)
    .then(() => {
      return Toast.success('Company successfully disliked');
    })
    .catch(() => {
      return Toast.error('Error company disliked');
    });
};

export const fetchCompaniesBlocked = (page: number, limit?: number) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompaniesBlocked(page, limit)
    .then(companies => {
      dispatch(actions.setCompaniesBlocked(companies));
    })
    .catch(err => {
      console.error(err);
    });
};

export const fetchCompanyBlock = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyBlock(id)
    .then(() => {
      dispatch(actions.setCompanyBlocked(id));
      Toast.success('Company successfully blocked');
      return 'ok';
    })
    .catch(() => Toast.error('Error company blocked'));
};

export const fetchCompanyUnlock = (id: string) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyUnlock(id)
    .then(() => {
      dispatch(actions.setCompanyBlocked(id));
      Toast.success('Company successfully unblocked');
      return 'ok';
    })
    .catch(() => Toast.error('Error company unblocked'));
};

export const fetchCompaniesUnlock = (id: string) => async () => {
  return api
    .fetchCompanyUnlock(id)
    .then(() => Toast.success('Company successfully unblocked'))
    .catch(() => Toast.error('Error company unblocked'));
};

export const fetchCompanyBlockState = (id: string) => async () => {
  return api
    .fetchCompanyBlockState(id)
    .catch(() => Toast.error('Error company get block state'));
};

export const fetchCompaniesTopMatch = () => async (dispatch: Dispatch) => {
  return api
    .fetchCompaniesTopMatch()
    .then(companies => {
      dispatch(actions.setCompaniesTopMatch(companies));
    })
    .catch(() => Toast.error('Error companies get topmatch'));
};

export const fetchCompaniesCustomizeFilter = (
  data: CustomizeFilterRequestProps,
) => async (dispatch: Dispatch) => {
  return api
    .fetchCustomizeFilter(data)
    .then(companies => {
      if (data.filters.dataType === 'contact') {
        dispatch(actions.setCompaniesContacts(companies as CompaniesContacts));
        return;
      }

      dispatch(actions.setCompanies(companies as Companies));
      return companies;
    })
    .catch(() => {
      Toast.error('Error companies customize filter');
    });
};

export const fetchCompaniesCustomizeFilterIds = (
  data: CustomizeFilterRequestProps,
) => async (dispatch: Dispatch) => {
  return api
    .fetchCustomizeFilterIds(data)
    .then(companies => {
      dispatch(actions.setCompaniesOnlyIdName(companies));
    })
    .catch(() => {
      Toast.error('Error get companies Ids');
    });
};

export const fetchCompanyMatchDetail = (companyId: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanyMatchDetail(companyId)
    .then(matchDetail => dispatch(actions.setCompanyMatchDetail(matchDetail)))
    .catch(() => Toast.error('Error get company detail'));
};

export const fetchCompanyMeeting = (companyId: string) => async (
  dispatch: Dispatch,
  getState: () => StateProps,
) => {
  const company = getState().companies.companies.items.find(
    item => item.id === companyId,
  );

  return api
    .fetchCompanyMeeting(companyId)
    .then(() => {
      company && dispatch(actions.setCompany({ ...company, isMeeting: true }));
      dispatch(actions.setLastMonthCompanyMeeting(companyId));
      dispatch(actions.setFavoritesCompanyMeeting(companyId));
    })
    .catch(() => Toast.error('Error company meeting'));
};

export const fetchCompanyDisMeeting = (companyId: string) => async (
  dispatch: Dispatch,
  getState: () => StateProps,
) => {
  const company = getState().companies.companies.items.find(
    item => item.id === companyId,
  );

  return api
    .fetchCompanyDisMeeting(companyId)
    .then(() => {
      company && dispatch(actions.setCompany({ ...company, isMeeting: false }));
      dispatch(actions.setLastMonthCompanyMeeting(companyId));
      dispatch(actions.setFavoritesCompanyMeeting(companyId));
    })
    .catch(() => Toast.error('Error company dismeeting'));
};

export const fetchCompanyPartnership = (companyId: string) => async (
  dispatch: Dispatch,
  getState: () => StateProps,
) => {
  const company = getState().companies.companies.items.find(
    item => item.id === companyId,
  );

  return api
    .fetchCompanyPartnership(companyId)
    .then(() => {
      company && dispatch(actions.setCompany({ ...company, isPartnership: true }));
      dispatch(actions.setLastMonthCompanyPartnership(companyId));
      dispatch(actions.setFavoritesCompanyPartnership(companyId));
    })
    .catch(() => Toast.error('Error company partnership'));
};

export const fetchCompanyDisPartnership = (companyId: string) => async (
  dispatch: Dispatch,
  getState: () => StateProps,
) => {
  const company = getState().companies.companies.items.find(
    item => item.id === companyId,
  );

  return api
    .fetchCompanyDisPartnership(companyId)
    .then(() => {
      company && dispatch(actions.setCompany({ ...company, isPartnership: false }));
      dispatch(actions.setLastMonthCompanyPartnership(companyId));
      dispatch(actions.setFavoritesCompanyPartnership(companyId));
    })
    .catch(() => Toast.error('Error company disPartnership'));
};

export const fetchCompanyCurrent = () => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyCurrent()
    .then(company => dispatch(actions.setCompanyCurrent(company)))
    .catch(() => Toast.error('Error get current company'));
};

export const fetchCompanyCurrentUpdate = (
  data: SearchCompany,
  config?: {
    hideToast?: boolean;
  },
) => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyCurrentUpdate(data)
    .then(company => {
      !config?.hideToast && Toast.success('Company successfully updated');
      dispatch(actions.setCompanyCurrent(company));
    })
    .catch(error => {
      fetchErrorHandler(error);
    });
};

export const fetchCompanyCurrentLogoUpload = (file: File) => async () => {
  return api
    .fetchCompanyCurrentLogoUpload(file)
    .then(() => {
      Toast.success('File successfully uploaded');
    })
    .catch(() => Toast.error('Error upload file'));
};

export const fetchSimilaritySearchCompany = (query: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchSimilaritySearchCompany(query)
    .then(companies => {
      dispatch(actions.setSimilaritySearchCompany(companies));
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchCompanyCurrentContacts = () => async (dispatch: Dispatch) => {
  return api
    .fetchCompanyCurrentContacts()
    .then(contacts => {
      dispatch(actions.setCompanyCurrentContacts(contacts));
    })
    .catch(error => {
      console.error(error);
    });
};
export const fetchCompanyCurrentContactsCreate = (contact: CompanyContact) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanyCurrentContactsCreate(contact)
    .then(contacts => {
      dispatch(actions.setCompanyCurrentContactCreate(contacts));
    })
    .catch(error => {
      console.error(error);
    });
};
export const fetchCompanyCurrentContactsUpdate = (contact: CompanyContact) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanyCurrentContactsUpdate(contact)
    .then(contacts => {
      dispatch(actions.setCompanyCurrentContactUpdate(contacts));
    })
    .catch(error => {
      console.error(error);
    });
};
export const fetchCompanyCurrentContactsDelete = (id: string) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchCompanyCurrentContactsDelete(id)
    .then(() => {
      dispatch(actions.setCompanyCurrentContactDelete(id));
    })
    .catch(error => {
      console.error(error);
    });
};

export const clearSimilaritySearchCompany = () => (dispatch: Dispatch) => {
  dispatch(actions.clearSimilaritySearchCompany());
};

export const fetchAddTeamCompanyMembers = (email: string) => async () => {
  try {
    await api.fetchAddTeamCompanyMembers(email);

    Toast.success('Email sent successfully');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      error.response?.data.message === 'Validation failed' &&
        Toast.error('Email must be a valid email');
    }
  }
};

export const fetchInviteLinkTeamCompany = () => async () => {
  try {
    const link = await api.fetchInviteLinkTeamCompany();

    Toast.success('Invite link was generated successfully');

    return link;
  } catch (error) {
    console.error(error);
  }
};

export const fetchAcceptInviteCompany = (params: AcceptInviteData) => async () => {
  try {
    await api.fetchAcceptInviteCompany(params);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      error.response?.data.error === 'Unauthorized' &&
        Toast.error('You are not authorized');

      error.response?.data.error === 'Forbidden' &&
        Toast.error(error.response.data.message);

      console.log(error);
    }
  }
};

export const fetchTeamCompanyMembers = () => async (dispatch: Dispatch) => {
  try {
    const data = await api.fetchTeamCompanyMembers();
    dispatch(actions.getTeamMembers(data));
  } catch (error) {
    console.log(error);
  }
};

export const fetchDeleteTeamCompanyMembers = (id: string) => async (
  dispatch: Dispatch,
) => {
  try {
    await api.fetchDeleteTeamCompanyMembers(id);
    dispatch(actions.deleteTeamMembers(id));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      Toast.error(error.response?.data.message);
    }
  }
};

export const fetchLastWeekCompanies = (lastDate: string) => async () => {
  return api.fetchLastWeekCompanies(lastDate);
};

export const fetchCompaniesNamesByIds = (ids: string[]) => async () => {
  return api.fetchCompaniesByIds(ids).then(res => res.map(item => item.name));
};

export const fetchLastMonthCompanies = (params: PaginationReq) => async (
  dispatch: Dispatch,
) => {
  return api
    .fetchLastMonthCompanies(params)
    .then(companies => dispatch(actions.setLastMonthCompanies(companies)))
    .catch(() => Toast.error('Get Recently Added Companies Error'));
};

export const fetchSubscriptionCompany = () => async (dispatch: Dispatch) => {
  return api
    .fetchSubscriptionCompany()
    .then(subscription => dispatch(actions.setCompanySubscription(subscription)));
};

// export const fetchUpdateCompanyProfile = (companyId: string) => async (
//   dispatch: Dispatch,
// ) => {
//   return api.fetchUpdateCompanyProfile(companyId).then(profile => {
//     dispatch(actions.updateCompanyProfile(profile));
//   });
// };

export const fetchUpdateEnrichSingle = (companyId: string) => async () => {
  return api
    .fetchUpdateEnrich([companyId])
    .then(companyNames => {
      if (!companyNames.length)
        return Toast.success('No Social Impact data was found for this company.');

      Toast.success('Social Impact data for this company has been updated successfully.');
    })
    .catch(fetchErrorHandler);
};

export const fetchUpdateEnrich = (companyIds: string[]) => async () => {
  return api.fetchUpdateEnrich(companyIds).then(() => {
    Toast.success('Update successfully');
  });
};

export const fetchSupportEmail = (params: ReqSupportEmail) => async () => {
  return api
    .fetchSupportEmail(params)
    .then(() => {
      Toast.success('Send successfully');
    })
    .catch(err => {
      fetchErrorHandler(err);
    });
};
