import { CampaignStoreData } from 'interfaces/campaign';
import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './actions';
import * as types from './types';

type RootAction = ActionType<typeof actions>;

export default createReducer<CampaignStoreData, RootAction>({
  campaignList: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  reportList: {
    items: [],
    meta: {
      currentPage: '',
      itemCount: 0,
      itemsPerPage: '',
      totalItems: 0,
      totalPages: 0,
    },
  },
  campaign: undefined,
  donationDetails: undefined,
  einSuggest: {
    data: [],
    msg: '',
  },
  atworkPartnerPreviewUrl: '',
  currentAllCampaigns: [],
  partnersByCampaign: [],
  campaignReportList: [],
  campaignQRCode: '',
})
  .handleType([types.SET_Campaign_LIST], (state, action) => ({
    ...state,
    campaignList: action.payload,
  }))
  .handleType([types.SET_Report_LIST], (state, action) => ({
    ...state,
    reportList: action.payload,
  }))
  .handleType([types.CREATE_Campaign], (state, action) => ({
    ...state,
    // campaignList: {
    //   items: [action.payload, ...state.campaignList.items],
    //   meta: {
    //     ...state.campaignList.meta,
    //     totalItems: state.campaignList.meta.totalItems + 1,
    //   },
    // },
  }))
  .handleType([types.DELETE_Campaign], (state, action) => ({
    ...state,
    campaignList: {
      items: state.campaignList.items.filter(item => !action.payload.includes(item.id)),
      meta: {
        ...state.campaignList.meta,
        totalItems: state.campaignList.meta.totalItems - action.payload.length,
      },
    },
  }))
  .handleType([types.SET_Campaign], (state, action) => ({
    ...state,
    campaign: action.payload,
    campaignList: {
      ...state.campaignList,
      items: state.campaignList.items.map(item => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }
        return item;
      }),
    },
  }))
  .handleType([types.GET_DONATION_DETAILS], (state, action) => ({
    ...state,
    donationDetails: action.payload.data,
  }))
  .handleType([types.SET_EIN_SUGGEST], (state, action) => ({
    ...state,
    einSuggest: action.payload,
  }))
  .handleType([types.SET_ATWORK_PARTNER_PREVIEW_URL], (state, action) => ({
    ...state,
    atworkPartnerPreviewUrl: action.payload,
  }))
  .handleType([types.SET_CURRENT_ALL_CAMPAIGNS], (state, action) => ({
    ...state,
    currentAllCampaigns: action.payload,
  }))
  .handleType([types.SET_PARTNERS_BY_CAMPAIGN], (state, action) => ({
    ...state,
    partnersByCampaign: action.payload,
  }))
  .handleType([types.SET_CAMPAIGN_REPORTS], (state, action) => ({
    ...state,
    campaignReportList: action.payload,
  }))
  .handleType([types.SET_CAMPAIGN_QRCODE], (state, action) => ({
    ...state,
    campaignQRCode: action.payload,
  }))
  .handleType([types.CLEAR_CAMPAIGN_QRCODE], (state, action) => ({
    ...state,
    campaignQRCode: action.payload,
  }))
  .handleType([types.CLEAR_REPORT_PAGE], state => ({
    ...state,
    campaignReportList: [],
  }));
