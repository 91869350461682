/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useDropdown from 'use-dropdown';
import { useSelector } from 'react-redux';
import { useOperation } from 'hooks/common';
import { fetchTeam } from 'store/ducks/team/operations';
import { getTeam } from 'store/ducks/team/selectors';
import { Location } from 'history';

import { UILoadAvatar } from 'components/UI';

import styled from 'styled-components';

export interface HeaderDropDownProps {
  handleLogout: () => void;
  location: Location;
  isSubscription?: boolean;
}

const HeaderDropDown = ({
  handleLogout,
  location,
  isSubscription,
}: HeaderDropDownProps) => {
  const [containerRef, isOpen, open, close] = useDropdown();

  const [onfetchTeam, loading] = useOperation(fetchTeam);

  const team = useSelector(getTeam);

  useEffect(() => {
    onfetchTeam(undefined);
  }, [getTeam]);

  const { pathname } = location;
  useEffect(close, [close, pathname]);

  return (
    <Root ref={containerRef}>
      <Inner onClick={open}>
        <Image imgKey={team?.logoKey} loading={loading} />
        <TitleAndSupportLink>
          <Title>{team?.organizationName || 'No name'}</Title>
          <KnowledgeLink
            href="https://support.accelerist.com/knowledge"
            onClick={e => {
              e.preventDefault();
              const confirmed = window.confirm(
                'This will open a new tab to the Knowledge page. Do you want to proceed?',
              );
              if (confirmed) {
                window.open('https://support.accelerist.com/knowledge', '_blank');
              }
            }}
          >
            Knowledge Base
          </KnowledgeLink>
          <SupportLink
            href="https://support.accelerist.com/knowledge/kb-tickets/new"
            onClick={e => {
              e.preventDefault();
              const confirmed = window.confirm(
                'This will open a new tab to the Support page. Do you want to proceed?',
              );
              if (confirmed) {
                window.open(
                  'https://support.accelerist.com/knowledge/kb-tickets/new',
                  '_blank',
                );
              }
            }}
          >
            Support
          </SupportLink>
        </TitleAndSupportLink>
      </Inner>

      {isOpen && (
        <List>
          {isSubscription && (
            <>
              <LinkElem to="/profile">Profile</LinkElem>
              <LinkElem to="/user-profile">User Profile</LinkElem>
              <LinkElem to="/teams">Users</LinkElem>
              <LinkElem to="/password">Password</LinkElem>
              <LinkElem to="/setting">Settings</LinkElem>
              <LinkElem to="/email-design">Email Design</LinkElem>
            </>
          )}
          <Logout onClick={handleLogout}>Log out</Logout>
        </List>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: 177px;
  cursor: pointer;
  position: relative;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled(UILoadAvatar)`
  border-radius: 6px;
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  margin-right: 12px;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

const TitleAndSupportLink = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-size: 12px;
  line-height: 150%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 48px);
`;

const SupportLink = styled.a`
  font-size: 9px;
  color: blue;
  text-decoration: underline;
`;

const KnowledgeLink = styled.a`
  font-size: 9px;
  color: blue;
  text-decoration: underline;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 48px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(40, 31, 61, 0.04);
  border-radius: 6px;
  padding: 24px;
  z-index: 9;
`;

const LinkElem = styled(Link)`
  font-size: 12px;
  color: var(--black);
  &:not(:last-child) {
    margin-bottom: 14px;
  }
  &:hover {
    color: var(--darkGray);
  }
`;

const Logout = styled.div`
  font-size: 12px;
  color: var(--red);
  &:hover {
    color: #eb1c2d;
  }
`;

export default HeaderDropDown;
