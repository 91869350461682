import { StateProps } from 'store/interfaces';
import { createSelector } from 'reselect';

export const selectAuthStatus = state => state.user.user.authStatus;
export const selectUser = (state: StateProps) => state.user.user;
export const selectAdditionalInfo = (state: StateProps) => state.user.additionalInfo;
export const getVerifyEmailStatus = (state: StateProps) => state.user.verifyEmailStatus;

export const selectUserPersonalInfo = state => {
  const {
    email,
    firstName,
    lastName,
    isReceivingNotifications,
    linkedinLink,
    jobTitle,
  } = state.user.user;

  return {
    email,
    firstName,
    lastName,
    isReceivingNotifications,
    linkedinLink,
    jobTitle,
  };
};

export const getMissions = createSelector(selectAdditionalInfo, info => info.missions);
export const getCrsFocus = createSelector(selectAdditionalInfo, info => info.crsFocus);
export const getTeamRange = createSelector(selectAdditionalInfo, info => info.teamRange);
export const getStateByCountryOptions = (state: StateProps) =>
  state.user.stateByCountry.map(item => ({ label: item, value: item }));
export const getCitesByStatesOptions = (state: StateProps) =>
  state.user.citesByStates.map(item => ({ label: item, value: item }));
