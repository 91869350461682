import { UIButton } from 'components/UI';
import {
  ContactCombineCompany,
  CustomizeTemplateGetReq,
  Gameplan,
  NewGameplan,
  GameplanBase,
  GameplanEmailPreviewReq,
} from 'interfaces/gameplan';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import StepOneIcon from 'assets/images/icons/step-one.svg';
import StepTwoIcon from 'assets/images/icons/step-two.svg';
import SelectContacts from './SelectContacts';
import GamePlanTemplate, { GameplanFormData } from './GamePlanTemplate';
import { Form, FormRenderProps } from 'react-final-form';
import AddOwnContactPop from '../AddOwnContactPop';
import { useHistory, useParams } from 'react-router';
import Enumerable from 'linq';
import { v4 as uuidv4 } from 'uuid';
import Toast from 'services/Toast';
import GameplanCreatedModal from 'components/Modals/GameplanCreatedModal';
import GameplanDraftModal from 'components/Modals/GameplanDraftModal';
import { useModal } from 'hooks/common';
import arrayMutators from 'final-form-arrays';
import { FormApi } from 'final-form';
import { OnCheckParams } from 'components/UI/interfaces';
import { TeamProfileData } from 'interfaces/team';
import { FieldSwitcher } from 'components/FormFields';
import { Field } from 'components/FinalForm';
import { PAGE_WIDTH } from 'constants/common';
import JumpAlertLink from 'components/JumpAlertLink';
import { SuggestCompany } from 'interfaces/companies';

interface Props {
  contacts: ContactCombineCompany[];
  search: { type?: string };
  initialValues: {} | GameplanFormData;
  defaultCheckedContactsId?: string[];
  gameplan: NewGameplan;
  team: TeamProfileData;
  showCustomizeTemplateModal: () => void;
  showPreviewModal: (payload: GameplanEmailPreviewReq) => void;
  onUpdateNewGameplanTemplate: (args: GameplanFormData) => Promise<void>;
  onFetchGameplanCustomizeTemplate: (args: CustomizeTemplateGetReq) => Promise<void>;
  onCreateGameplan: (args: NewGameplan) => Promise<GameplanBase>;
  onSaveDraftGameplan: (args: NewGameplan) => Promise<GameplanBase>;
  onResetFormToDefault: () => Promise<NewGameplan | undefined>;
  handleGetCompanies: (val: string) => void;
  companiesSearchList: SuggestCompany[];
}

const GameplanForm = ({
  contacts,
  search,
  initialValues,
  gameplan,
  team,
  defaultCheckedContactsId,
  showCustomizeTemplateModal,
  showPreviewModal,
  onUpdateNewGameplanTemplate,
  onFetchGameplanCustomizeTemplate,
  onCreateGameplan,
  onSaveDraftGameplan,
  onResetFormToDefault,
  companiesSearchList,
  handleGetCompanies,
}: Props) => {
  const [step, setStep] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [resetBtnLoading, setResetBtnLoading] = useState(false);
  const [gameplanId, setGameplanId] = useState('');
  const [gamePlanType, setGamePlanType] = useState('');
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedContactsIds, setCheckedContactsId] = useState<string[]>([]);

  const params: { prospectsId: string; gameplanId: string } = useParams();
  const { push, go } = useHistory();

  const previewContact = useMemo(
    () => contacts.filter(item => checkedContactsIds.includes(item.id))?.[0],
    [contacts, checkedContactsIds],
  );

  const onCheckRow = ({ check, id }: OnCheckParams) => {
    if (check) {
      const result = [...checkedContactsIds, id];
      setCheckedContactsId(result);
      return;
    }
    const result = [...checkedContactsIds].filter(item => item !== id);
    setCheckedContactsId(result);
  };

  const onCheckAll = (bool: boolean) => {
    setIsCheckedAll(bool);
    if (bool) {
      const result = contacts.reduce((prev, curr) => {
        if (curr.usedIn60Days === '0') {
          prev.push(curr.id);
          return prev;
        }
        return prev;
      }, [] as string[]);

      setCheckedContactsId(result);
      return;
    }
    setCheckedContactsId([]);
  };

  const [showGameplanCreatedModal, hideGameplanCreatedModal] = useModal(() => {
    return (
      <GameplanCreatedModal
        onClose={hideGameplanCreatedModal}
        handleSubmit={() => {
          hideGameplanCreatedModal();
          push(`/prospects/${params.prospectsId}`);
        }}
      />
    );
  });
  const [showGameplanDraftModal, hideGameplanDraftModal] = useModal(() => {
    return (
      <GameplanDraftModal
        onClose={hideGameplanDraftModal}
        handleSubmit={() => {
          hideGameplanDraftModal();

          if (!params.gameplanId)
            push(
              `/gameplan/edit/${params.prospectsId}/${gameplanId}?type=${gamePlanType}`,
            );
        }}
      />
    );
  }, [gameplanId, gamePlanType]);

  const handleReset = async (form: FormApi<Gameplan, Partial<Gameplan>>) => {
    setResetBtnLoading(true);
    const resetNewGameplan = await onResetFormToDefault();
    if (!resetNewGameplan) return setResetBtnLoading(false);

    form.change(
      'gamePlanTemplates',
      gameplan.gamePlanTemplates.map((item, i) => ({
        ...item,
        attachments: resetNewGameplan.gamePlanTemplates[i].attachments || [],
        body: resetNewGameplan.gamePlanTemplates[i].body || '',
        deploymentDay: resetNewGameplan.gamePlanTemplates[i].deploymentDay || 0,
        name: resetNewGameplan.gamePlanTemplates[i].name || '',
        subject: resetNewGameplan.gamePlanTemplates[i].subject || '',
      })),
    );
    form.change('isBbc', false);

    const result = [];
    setCheckedContactsId(result);

    setResetBtnLoading(false);
  };

  const onSend = async (values: GameplanFormData, isDraft = false) => {
    const { gamePlanTemplates, isBbc, isClose, autoReplyDelete } = values;

    if (gamePlanTemplates[1].deploymentDay < 2) {
      Toast.error('Deployment day for the 2nd email should not less than Day 2');
      return;
    }
    if (gamePlanTemplates[2].deploymentDay < 10) {
      Toast.error('Deployment day for the 3rd email should not less than Day 10');
      return;
    }
    if (gamePlanTemplates[1].deploymentDay <= gamePlanTemplates[0].deploymentDay) {
      Toast.error(
        'Deployment day for the 2nd email should not less than that of the 1st email',
      );
      return;
    }
    if (gamePlanTemplates[2].deploymentDay <= gamePlanTemplates[1].deploymentDay) {
      Toast.error(
        'Deployment day for the 3rd email should not less than that of the 2nd email',
      );
      return;
    }

    const resultGameplan = gameplan;
    resultGameplan.gamePlanTemplates = gamePlanTemplates;
    resultGameplan.contactIds = Enumerable.from(contacts)
      .where(
        x =>
          checkedContactsIds.includes(x.id) &&
          (x.isOwnContact || false) === false &&
          x.usedIn60Days === '0',
      )
      .select(x => x.id)
      .toArray();
    resultGameplan.ownContacts = Enumerable.from(contacts)
      .where(
        x =>
          checkedContactsIds.includes(x.id) &&
          (x.isOwnContact || false) === true &&
          x.usedIn60Days === '0',
      )
      .select(x => {
        return {
          firstName: x.firstName,
          lastName: x.lastName,
          jobTitle: x.jobTitle,
          companyId: x.companyId,
          email: x.email,
        };
      })
      .toArray();

    resultGameplan.isBbc = isBbc;
    resultGameplan.isClose = isClose;
    resultGameplan.isDraft = isDraft;
    resultGameplan.autoReplyDelete = autoReplyDelete;

    if (isDraft)
      return onSaveDraftGameplan(resultGameplan).then(gameplan => {
        setGameplanId(gameplan.id);
        setGamePlanType(gameplan.gamePlanType);
        showGameplanDraftModal();
      });

    return onCreateGameplan(resultGameplan)
      .then(showGameplanCreatedModal)
      .catch(() => {});
  };

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const onAddOwnContact = (newContact: ContactCombineCompany) => {
    newContact.id = uuidv4();
    newContact.isOwnContact = true;
    newContact.usedIn60Days = '0';
    contacts.push(newContact);
    onCheckRow({ check: true, id: newContact.id });
    setIsOpen(false);
  };

  const onBack = useCallback(() => {
    if (step === 1) {
      return;
    }
    setStep(step - 1);
  }, [step]);

  const onContinue = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  useEffect(() => {
    if (!defaultCheckedContactsId) return;

    setCheckedContactsId(defaultCheckedContactsId);
  }, [defaultCheckedContactsId]);

  return (
    <Form
      onSubmit={({ submitType, ...values }: Gameplan & { submitType?: 'draft' }) => {
        switch (submitType) {
          case 'draft':
            return onSend(values, true);
          default:
            return onSend(values, false);
        }
      }}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ values: true, submitting: true }}
      initialValues={initialValues}
      render={({
        handleSubmit,
        values,
        form,
        submitting,
      }: FormRenderProps<GameplanFormData & { submitType?: 'draft' }>) => (
        <>
          <Field
            name="autoReplyDelete"
            label="Remove recipients from Game Plan if they have replied to any game plan
                emails (excluding auto-replies)"
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            component={Switch}
          />
          {step === 1 && (
            <>
              <StepTitle>
                <StepNumber src={StepOneIcon} />
                Please select contacts to include in the Game Plan.
              </StepTitle>
              <SelectContacts
                datasource={contacts}
                openAddContactsModal={openModal}
                checkedContactsIds={checkedContactsIds}
                isCheckedAll={isCheckedAll}
                onCheckRow={onCheckRow}
                onCheckAll={onCheckAll}
              />
            </>
          )}
          {step === 2 && (
            <>
              <StepTitle>
                <StepNumber src={StepTwoIcon} />
                Edit templates
                <AlertLink path="/email-design">Email Design</AlertLink>
              </StepTitle>
              <GamePlanTemplate
                type={search.type}
                team={team}
                showCustomizeTemplateModal={showCustomizeTemplateModal}
                showPreviewModal={body =>
                  showPreviewModal({
                    body,
                    contact: {
                      id: previewContact.isOwnContact ? '' : previewContact.id,
                      companyId: previewContact.companyId,
                      email: previewContact.email || '',
                      firstName: previewContact.firstName || '',
                      lastName: previewContact.lastName || '',
                    },
                  })
                }
                onFetchGameplanCustomizeTemplate={customizeTemplateReq => {
                  onUpdateNewGameplanTemplate(values);
                  onFetchGameplanCustomizeTemplate(customizeTemplateReq);
                }}
              />
            </>
          )}
          <AddOwnContactPop
            onClose={closeModal}
            isOpen={modalIsOpen}
            onSubmit={onAddOwnContact}
            companiesSearchList={companiesSearchList}
            handleGetCompanies={handleGetCompanies}
          />

          <BtnGroup step={step}>
            {step === 1 && (
              <>
                <StepBtn
                  disabled={!checkedContactsIds.length}
                  title="Continue"
                  modifiers="primary"
                  handleClick={onContinue}
                />
              </>
            )}

            {step === 2 && (
              <>
                <StepBtn title="Back" modifiers="secondary" handleClick={onBack} />
                <StepBtn
                  title="Send"
                  isLoading={submitting && values.submitType === undefined}
                  modifiers="primary"
                  handleClick={() => {
                    form.change('submitType', undefined);
                    handleSubmit();
                  }}
                />
              </>
            )}
            <StepBtn
              title="Save as Draft"
              isLoading={submitting && values.submitType === 'draft'}
              modifiers="secondary"
              handleClick={() => {
                form.change('submitType', 'draft');
                handleSubmit();
              }}
            />

            <div style={{ marginLeft: 'auto', display: 'flex' }}>
              {params.gameplanId && (
                <StepBtn
                  title="Reset to Default"
                  modifiers="secondary"
                  isLoading={resetBtnLoading}
                  handleClick={() => handleReset(form)}
                />
              )}
              <StepBtn title="Cancel" modifiers="danger" handleClick={() => go(-1)} />
            </div>
          </BtnGroup>
        </>
      )}
    />
  );
};

const StepTitle = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 16px;
`;

const StepNumber = styled(ReactSVG)`
  display: inline-block;
  margin-right: 8px;
`;

const BtnGroup = styled.div<{ step?: number }>`
  display: flex;
  max-width: ${({ step }) => (step === 1 ? `${PAGE_WIDTH.NORMAL}px` : '1314px')};
`;

const StepBtn = styled(UIButton)`
  width: 146px;
  margin-right: 8px;
  padding: 10px 0;
  border-radius: 6px;
  font-size: 16px;
`;

const Switch = styled(FieldSwitcher)`
  width: max-content;

  label > span {
    font-size: 16px;
    line-height: 145%;
    color: #979797;
  }
`;

const AlertLink = styled(JumpAlertLink)`
  margin-left: auto;
`;

export default GameplanForm;
