import { PaginationList, PaginationMeta, PaginationReq } from './common';

export interface CampaignData {
  id: string;
  campaignName?: string;
  campaignDescription: string;
  startDate: string;
  endDate: string;
  fundraisingGoal: string;
  status?: string;
  potential?: string;
  preview?: number;
}

export interface ReportData {
  campaignName: string;
  partnerName: string;
  donorName: string;
  donorEmail: string;
  postalCode: string;
  transactionDate: string;
  donationGrossAmount: number;
  donationNetAmount: number;
  donorCoveredFees: string;
  recurringFrequency: string;
  payPalDonation: string;
  atWorkDonation: string;
}

export interface ReportList {
  campaignName: string;
  partnerName: string;
  donorName: string;
  donorEmail: string;
  postalCode: string;
  transactionDate: string;
  donationGrossAmount: number;
  donationNetAmount: number;
  donorCoveredFees: string;
  recurringFrequency: string;
  payPalDonation: string;
  atWorkDonation: string;
}

export interface PartnerByCampaign {
  companyName: string;
  id: string;
}

export interface CampaignCopyReq {
  campaignName: string;
  campaignDescription: string;
  startDate: string;
  endDate: string;
  fundraisingGoal: string;
  copyId: string;
}

export interface GiveandShopData {
  campaignId: string;
  selectCampaignColors: string[];
  selectCampaignFont: string;
  textColor: string;
  setDonationAmounts: string;
  donationRequestMessage: string;
  backgroundColor: string;
  buttonsColor: string;
  customContent: string;
  files: File[];
  selectCampaignType: string;
  setDonation1: string;
  setDonation2: string;
  donorCoveredFees: boolean;
}

export interface CampaignStoreData {
  campaignList: CampaignListRes;
  reportList: ReportListRes;
  campaign: CampaignData | undefined;
  donationDetails: ConfigDonation | undefined;
  einSuggest: EinListRes;
  atworkPartnerPreviewUrl: string;
  currentAllCampaigns: CampaignData[];
  partnersByCampaign: PartnerByCampaign[];
  campaignReportList: CampaignReportListItem[];
  campaignQRCode: string;
}

export interface CampaignListRes {
  items: CampaignData[];
  meta: PaginationMeta;
}

export interface ReportListRes {
  items: ReportData[];
  meta: PaginationMeta;
}

export interface CampaignListReq extends PaginationReq {
  operation?: string;
}

export interface ReportListReq {
  reportType: string;
  campaignId?: string;
  partnerId?: string;
  dateRange?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  limit?: number;
}

export interface CamParListReq extends PaginationReq {
  operation?: string;
  campaignId?: string;
}

export interface CamParListItem {
  id: string;
  name: string;
  companyId: string;
  teamId: string;
  companyName: string;
  partnerDescription: string;
  partnerLogo: string;
  startDate: string;
  endDate: string;
  selected: 1 | 0;
  token: string;
  campaignPartnerId: string;
  previewUrl: string;
  companyCode?: string;
}

export type CamParListRes = PaginationList<CamParListItem>;

export interface CampaignSavePartnersList {
  campaignId?: string;
  partners: {
    partnerId: string;
    partnerLogo?: string;
  }[];
  status?: string;
}

export interface SaveParList {
  campaignId?: string;
  partners: {
    partnerId: string;
    partnerLogo?: string;
  }[];
  status?: string;
}

export interface CampaignCreateReq {
  campaignName: string;
  campaignDescription: string;
  startDate: string;
  endDate: string;
  fundraisingGoal: string;
}

export interface ConfigDonation {
  id: string;
  type: string | number;
  selectCampaignType: string;
  selectCampaignColors: string[];
  selectCampaignFont: string;
  setDonation1: string;
  setDonation2: string;
  donationRequestMessage: string;
  file: string;
  donorCoveredFees: boolean | string;
  customContentImageName: string;
  customContentVideoName: string;
  donationReceiptImage: string;
  customContentType: string;
  donorCurrencySetting: string;
  donorFixedFees: string;
  status: number;
  includeCoupon: string | number;
  couponText: string;
  couponImage: string;
  couponCodes: string;
  couponConfirmationMessage: string;
  couponEmailReceiptMessage: string;
  roundUp: boolean | string;
  shareDonorInformation: string;
  receiptMessage: string;
  customMessage: string;
  receiptEmailMessage: string;
}

export const headTable = [
  'Campaign Name',
  'Campaign Description',
  'Launch Date',
  'Fundraising Goal',
  'Potential ($)',
  '',
  '',
];

export const headParTable = ['Company Name', 'Description', 'Start Date', 'End Date'];

export const CampListDropdownOptions = [
  { label: 'Edit', value: 'Edit' },
  { label: 'Duplicate', value: 'Duplicate' },
];

export interface EinParam {
  paypalGivingFundId: string;
}

export interface EinDataRes {
  city: string;
  country: string;
  ein: string;
  name: string;
  paypalGivingFundId: string;
  phone: string;
  postal_code: string;
  state: string;
  paypalEin: string;
}

export interface EinListRes {
  data: EinDataRes[];
  msg: string;
}

export interface EinListRes1 {
  data: {};
  msg: string;
  status: number;
}

export interface CampaignReportListReq {
  reportType: string;
  campaignId?: string;
  partnerId?: string;
  dateRange?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
}

export interface CampaignReportListItem {
  campaignName: string;
  partnerName: string;
  donorName: string;
  donorEmail: string;
  postalCode: string;
  transactionDate: string;
  donationGrossAmount: number;
  donationNetAmount: number;
  donorCoveredFees: string;
  recurringFrequency: string;
  payPalDonation: string;
  atWorkDonation: string;
}

export interface CampaignReportExportReq extends CampaignReportListReq {
  exportType: 'csv' | 'xlsx';
}

export interface CampaignShareCodeReq {
  partnerId: string;
  email: string;
}
