/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { Form, FormRenderProps } from 'react-final-form';
import History from 'services/History';

import { FieldTextInput, FieldSwitcher } from 'components/FormFields';
import { OnSubmitReturnType } from 'interfaces/finalForm';
import { UIButton } from 'components/UI';
import { required } from 'utils/validation';
import { UserProfile } from '../interfaces';
import LogoutIcon from 'assets/images/icons/log-out.svg';

import styled from 'styled-components';
import UploadProfilePhoto from 'components/UploadProfilePhoto';
import { useLogout, useOperation } from 'hooks/common';
import {
  fetchUploadUserAvatar,
  fetchVerifyEmail,
  fetchVerifyEmailStatus,
} from 'store/ducks/user/operations';
import { useSelector } from 'react-redux';
import { StateProps } from 'store/interfaces';
import { VerifyEmailStatus } from 'interfaces/auth';
import { getVerifyEmailStatus, selectUser } from 'store/ducks/user/selectors';
import { identityField } from 'utils';
import { NavLink } from 'react-router-dom';
import { Field } from 'components/FinalForm';
import { useModal } from 'hooks/common';
import VerifyEmailModal from 'components/Modals/VerifiedEmailModal';

export interface FormProps {
  onSubmit: (values: UserProfile) => OnSubmitReturnType;
  initialValues?: UserProfile;
  isLoading?: boolean;
}

const UserProfileForm = ({ onSubmit, initialValues }: FormProps) => {
  const [onAvatarUpload] = useOperation(fetchUploadUserAvatar);
  const [onFetchVerifyEmailStatus] = useOperation(fetchVerifyEmailStatus);
  const [onFetchVerfiyEmail] = useOperation(fetchVerifyEmail);

  const user = useSelector(selectUser);
  const verifyEmailStatus = useSelector<StateProps, VerifyEmailStatus>(
    getVerifyEmailStatus,
  );
  const { logout } = useLogout();

  const [showVerifyEmailModal, hideVerifyEmailModal] = useModal(() => {
    return (
      <VerifyEmailModal
        accessHandler={() =>
          onFetchVerfiyEmail(undefined).then(() => hideVerifyEmailModal())
        }
        cancelHandler={hideVerifyEmailModal}
      />
    );
  });

  const handleAvatarUpload = useCallback(async (file: File) => {
    return onAvatarUpload(file);
  }, []);

  useEffect(() => {
    onFetchVerifyEmailStatus(undefined);
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        hasValidationErrors,
        form,
      }: FormRenderProps<UserProfile>) => {
        return (
          <form>
            <Header>
              <UploadProfilePhoto
                imgKey={user?.avatarKey}
                handleUpload={handleAvatarUpload}
              />

              <button type="button" onClick={logout}>
                <ReactSVG src={LogoutIcon} />
              </button>
            </Header>

            <Body>
              <FieldSection marginBottom="8px">
                <TitleSection>Personal Info</TitleSection>
                <Row>
                  <Field
                    name="firstName"
                    label="First Name"
                    component={FieldTextInput}
                    placeholder="Enter First Name"
                    validate={required}
                  />
                  <Field
                    name="lastName"
                    label="Last Name"
                    component={FieldTextInput}
                    placeholder="Enter Last Name"
                    validate={required}
                  />
                  <EmailStatusWrapper>
                    {verifyEmailStatus !== undefined &&
                      (verifyEmailStatus ? (
                        <VerifiedEmailLink>Verified</VerifiedEmailLink>
                      ) : (
                        <NotVerifiedEmailLink onClick={showVerifyEmailModal}>
                          Not Verified
                        </NotVerifiedEmailLink>
                      ))}
                    {/* <PendingVerifiedEmailLink>
                      Pending for Verification
                    </PendingVerifiedEmailLink> */}
                    <Field
                      name="email"
                      label="Email"
                      component={FieldTextInput}
                      placeholder="Enter Email"
                      validate={required}
                    />
                  </EmailStatusWrapper>
                  <Field
                    name="jobTitle"
                    label="Job Title"
                    component={FieldTextInput}
                    placeholder="Enter Job Title"
                  />
                  <Field
                    name="linkedinLink"
                    parse={identityField}
                    label="Linkedin"
                    component={FieldTextInput}
                    placeholder="Enter LinkedIn link"
                  />
                </Row>
              </FieldSection>

              <FieldSection marginBottom="8px">
                <TitleSection>Notification</TitleSection>
                <Row>
                  <Field
                    name="isReceivingNotifications"
                    label="Turn OFF/ON"
                    component={FieldSwitcher}
                  />
                </Row>
                <Row>
                  <LinkBlockList to="/companies/blocked">Blocked List</LinkBlockList>
                </Row>
              </FieldSection>

              <BtnGroup>
                <UIButton
                  handleClick={() => History.back()}
                  modifiers="secondary"
                  title="Cancel"
                />
                <UIButton
                  title="Save"
                  modifiers="primary"
                  handleClick={handleSubmit}
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                  isLoading={submitting}
                />
              </BtnGroup>
            </Body>
          </form>
        );
      }}
    />
  );
};

const LinkBlockList = styled(NavLink)`
  color: #f05658;
`;

const BtnGroup = styled.div`
  display: flex;
  margin-top: 40px;

  button:first-child {
    margin-right: 8px;
  }

  & button {
    width: 136px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

const FieldSection = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '32px'};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 40px;
  align-items: center;
`;

const TitleSection = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 148%;
  color: var(--black);
  margin-bottom: 24px;
`;

const Body = styled.div`
  background: #fff;
  padding: 40px;
  margin-bottom: 86px;
`;

const EmailStatusWrapper = styled.div`
  position: relative;
`;

const NotVerifiedEmailLink = styled.a`
  color: var(--warning-color);
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  right: 6px;
  z-index: 1;

  &:hover {
    text-decoration: underline;
  }
`;

const PendingVerifiedEmailLink = styled.span`
  color: var(--darkGray);
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  right: 6px;
  z-index: 1;
`;

const VerifiedEmailLink = styled(PendingVerifiedEmailLink)`
  color: var(--success-color);
`;

export default UserProfileForm;
