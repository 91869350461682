import {
  MissionProps,
  IndustriesProps,
  CRSFocus,
  StatesProps,
  CountriesProps,
  CitesProps,
  IndustriesTreeProps,
  NaicsCodeTreeProps,
} from 'store/interfaces';

export interface AuthData {
  email?: string;
  password?: string;
  rememberMe?: boolean;
  passwordConfirmation?: string;
  token?: string | string[] | undefined;
}

export interface AuthResponseData {
  accessToken: string;
  user: User;
}

export enum AuthStatuses {
  AUTHOREZED = 'authorized',
  UNATHORIZED = 'unauthorized',
}

export type VerifyEmailStatus = boolean | undefined;

export interface User {
  id: string;
  email?: string;
  password?: string;
  name?: string;
  firstName?: string | null;
  lastName?: string | null;
  isAuthorized?: boolean;
  teamId?: string | null;
  role?: TeamMemberRoles | null;
  linkedinId?: string | null;
  isReceivingNotifications?: boolean;
  avatarFileName?: string | null;
  avatarFileUrl?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
  avatarKey?: string;
  jobTitle?: string;
  companyId?: string;
  isCanva?: boolean;

  // frontend field
  authStatus: AuthStatuses;
}

export enum TeamMemberRoles {
  USER = 'user',
  ADMIN = 'admin',
  OWNER = 'owner',
  COMPANY_USER = 'companyUser',
  COMPANY_OWNER = 'companyOwner',
}

export interface AdditionalInfo {
  missions: MissionProps[];
  industries: IndustriesProps[];
  industriesTree: IndustriesTreeProps[];
  crsFocus: CRSFocus[];
  states: StatesProps[];
  countries: CountriesProps[];
  cites: CitesProps[];
  naicsCodeTree: NaicsCodeTreeProps[];
  teamRange: {
    annualtotalcontributionsmax: string;
    annualtotalcontributionsmin: string;
    numberofsocialfollowersmax: string;
    numberofsocialfollowersmin: string;
    minimumcommitmentmax: string;
    minimumcommitmentmin: string;
  };
}

export interface UserData {
  user: User;
  additionalInfo: AdditionalInfo;
  verifyEmailStatus: VerifyEmailStatus;
  stateByCountry: string[];
  citesByStates: string[];
}
