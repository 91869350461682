export const SET_Campaign_LIST = 'campaign/SET_Campaign_LIST';
export const SET_Campaign = 'campaign/SET_Campaign';
export const CREATE_Campaign = 'campaign/SET_Campaign_CREATE';
export const UPDATE_Campaign = 'campaign/SET_Campaign_UPDATE';
export const DELETE_Campaign = 'campaign/SET_Campaign_DELETE';
export const SAVE_CONFIG_DONATION = 'campaign/SAVE_CONFIG_DONATION';
export const GET_DONATION_DETAILS = 'campaign/GET_DONATION_DETAILS';
export const SET_EIN_SUGGEST = 'campaign/SET_EIN_SUGGEST';
export const SET_ATWORK_PARTNER_PREVIEW_URL = 'campaign/SET_ATWORK_PARTNER_PREVIEW_URL';
export const SET_CURRENT_ALL_CAMPAIGNS = 'campaign/SET_CURRENT_ALL_CAMPAIGNS';
export const SET_PARTNERS_BY_CAMPAIGN = 'campaign/SET_PARTNERS_BY_CAMPAIGN';
export const SET_CAMPAIGN_REPORTS = 'campaign/SET_CAMPAIGN_REPORTS';
export const SET_CAMPAIGN_QRCODE = 'campaign/SET_CAMPAIGN_QRCODE';
export const CLEAR_CAMPAIGN_QRCODE = 'campaign/CLEAR_CAMPAIGN_QRCODE';
export const CLEAR_REPORT_PAGE = 'campaign/CLEAR_REPORT_PAGE';
export const SET_Report_LIST = 'campaign/SET_Report_LIST';
