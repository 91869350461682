export const SET_SAVELIST_CONTACTS = 'gameplan/SET_SAVELIST_CONTACTS';
export const SET_NEW_GAMEPLAN = 'gameplan/SET_NEW_GAMEPLAN';
export const SET_GAMEPLANS = 'gameplan/SET_GAMEPLANS';
export const SET_VIEW_GAMEPLAN_TEMPLATE = 'gameplan/SET_VIEW_GAMEPLAN_TEMPLATE';
export const DELETE_GAMEPLANS_ITEM = 'gameplan/DELETE_GAMEPLAN';
export const SET_GAMEPLAN = 'gameplan/SET_GAMEPLAN';
export const SET_GAMEPLAN_NAME_AND_CLOSE = 'gameplan/SET_GAMEPLAN_NAME_AND_CLOSE';
export const SET_GAMEPLAN_NAME_AND_CLOSE_SINGLE =
  'gameplan/SET_GAMEPLAN_NAME_AND_CLOSE_SINGLE';
export const DELETE_GAMEPLAN_CONTACT = 'gameplan/DELETE_GAMEPLAN_CONTACT';
export const SET_GAMEPLAN_STATUS = 'gameplan/SET_GAMEPLAN_STATUS';
export const SET_GAMEPLANS_BY_TEAM = 'gameplan/SET_GAMEPLANS_BY_TEAM';
export const SET_GAMEPLAN_NAME_AND_CLOSE_BY_TEAM =
  'gameplan/SET_GAMEPLAN_NAME_AND_CLOSE_BY_TEAM';
export const SET_SUGGEST_GAMEPLANS = 'gameplan/SET_SUGGEST_GAMEPLANS';
export const SET_GAMEPLAN_CUSTOMIZE_TEMPLATE = 'gameplan/SET_GAMEPLAN_CUSTOMIZE_TEMPLATE';
export const SET_GAMEPLAN_CUSTOMIZE_TEMPLATE_PREVIEW =
  'gameplan/SET_GAMEPLAN_CUSTOMIZE_TEMPLATE_PREVIEW';
export const SET_NEW_GAMEPLAN_TEMPLATES = 'gameplan/SET_NEW_GAMEPLAN_TEMPLATES';
export const SET_NEW_GAMEPLAN_TEMPLATES_BY_FORM =
  'gameplan/SET_NEW_GAMEPLAN_TEMPLATES_BY_FORM';
export const SET_GAMEPLAN_EMAIL_ANALYTICS_CONTACTS =
  'gameplan/SET_GAMEPLAN_EMAIL_ANALYTICS_CONTACTS';
