import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  FieldMillionRangeSlider,
  FieldMoneyRangeSlider,
  FieldTextInput,
} from 'components/FormFields';
import FormSection from '../FormSection';
import { filterTeamsSectionTexts } from 'constants/search';
import {
  FieldSearchPanelWithCheckBoxes,
  FiledMultiSelectWithCheckBoxes,
} from 'components/FormFields';
import { FormTeamSectionProps } from 'components/Forms/interfaces';
import { formatValueEnum, SelectOption } from 'components/UI/interfaces';
import { Field } from 'components/FinalForm';

const CompanySection = ({
  states,
  missions,
  sdgGoals,
  typeOfInvestment,
  isCustomize,
  teamRange,
}: FormTeamSectionProps) => {
  const { company } = filterTeamsSectionTexts;

  const misstionsOptions: SelectOption[] = useMemo(
    () =>
      missions.map(item => ({
        label: item.label,
        value: item.label,
      })),
    [missions],
  );

  return (
    <FormSection marginTop="32px" {...company}>
      <Row>
        <Field
          name="missionFocus"
          label="Mission Focus"
          component={FieldSearchPanelWithCheckBoxes}
          options={misstionsOptions}
        />
        <Field
          name="sdgGoals"
          label="SDG Goals"
          component={FieldSearchPanelWithCheckBoxes}
          options={sdgGoals}
        />
      </Row>

      <Row>
        <Field
          name="location"
          label="State/Provience"
          component={FiledMultiSelectWithCheckBoxes}
          options={states}
          formatValue={formatValueEnum.BASE_ARRAY}
        />
        <Field
          name="typesOfInvestment"
          label="Type of Investment"
          component={FiledMultiSelectWithCheckBoxes}
          options={typeOfInvestment}
          formatValue={formatValueEnum.BASE_ARRAY}
        />
      </Row>

      <Row>
        <Field
          name="numberOfSocialFollowers"
          label="Social Followers Number"
          component={FieldMillionRangeSlider}
          min={0}
          max={Number(teamRange.numberofsocialfollowersmax)}
          step={Math.ceil(Number(teamRange.numberofsocialfollowersmax) / 1000)}
        />
        <Field
          name="grossReceiptsRange"
          label="Gross Receipts Range"
          min={0}
          max={Number(teamRange.annualtotalcontributionsmax)}
          step={Math.ceil(Number(teamRange.annualtotalcontributionsmax) / 1000)}
          component={FieldMoneyRangeSlider}
        />
      </Row>

      {!isCustomize && (
        <Row>
          <Field
            name="minimumCommitment"
            label="Minimum Commitment"
            min={0}
            max={Number(teamRange.minimumcommitmentmax)}
            step={Math.ceil(Number(teamRange.minimumcommitmentmax) / 1000)}
            component={FieldMillionRangeSlider}
          />
          <Field
            name="zipCode"
            label="Postal Code"
            component={FieldTextInput}
            placeholder={'Enter Postal Code'}
          />
        </Row>
      )}
    </FormSection>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: calc(50% - 8px);
  }
`;

export default CompanySection;
