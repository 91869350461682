export const SET_TEAM = 'team/SET_TEAM';
export const GET_TEAM_MEMBERS = 'team/GET_TEAM_MEMBERS';
export const SET_LAST_LOGINS = 'team/SET_LAST_LOGINS';
export const DELETE_TEAM_MEMBERS = 'team/DELETE_TEAM_MEMBERS';
export const SET_SUBSCRIPTION = 'team/SET_SUBSCRIPTION';
export const SET_SENT_PITCH = 'team/SET_SENT_PITCH';
export const SET_TEAMS = 'team/SET_TEAMS';
export const SET_TEAM_NEWS = 'team/SET_TEAM_NEWS';
export const SET_EMAIL_DESIGN = 'team/SET_EMAIL_DESIGN';
export const CLEAR_EMAIL_DESIGN = 'team/CLEAR_EMAIL_DESIGN';
export const SET_TEAMS_SUGGEST = 'team/SET_TEAMS_SUGGEST';
export const SET_TEAM_PAYPALGIVINGFUND_CANUPDATE =
  'team/SET_TEAM_PAYPALGIVINGFUND_CANUPDATE';
export const SET_TEAM_PAYPALGIVINGFUND_IFCONFIRM =
  'team/SET_TEAM_PAYPALGIVINGFUND_IFCONFIRM';
export const SET_IMPACT_GENOME = 'team/SET_IMPACT_GENOME';
